<template>
<div class="center-preview" :style='{"width":"1200px","margin":"0px auto","position":"relative","background":"none"}'>
	<div class="title" :style='{"border":"4px solid","boxShadow":"1px 2px 6px #ccc","margin":"20px auto 20px","color":"#fff","borderRadius":"4px","textAlign":"center","background":"linear-gradient(135deg, rgba(17,112,210,1) 0%, rgba(8,179,68,1) 100%)","width":"100%","fontSize":"30px","lineHeight":"100px","fontWeight":"600"}'>{{ title }}</div>

    <el-tabs tab-position="left" :style='{"border":"1px solid #9ce3b5","padding":"30px 20px","boxShadow":"0px 2px 6px #ccc,inset 0px 0px 600px 0px #e0f8e8","margin":"20px auto 0 auto","borderRadius":"4px","background":"#fff","width":"100%"}' @tab-click="handleClick">
      <el-tab-pane label="个人中心">
        <el-form class="center-preview-pv" ref="sessionForm" :model="sessionForm" :rules="rules" label-width="80px">
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="护工工号" prop="hugonggonghao">
            <el-input v-model="sessionForm.hugonggonghao" placeholder="护工工号" readonly></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="密码" prop="mima">
            <el-input type="password" v-model="sessionForm.mima" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="护工姓名" prop="hugongxingming">
            <el-input v-model="sessionForm.hugongxingming" placeholder="护工姓名" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="性别">
            <el-select v-model="sessionForm.xingbie" placeholder="请选择性别" >
              <el-option v-for="(item, index) in dynamicProp.xingbie" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="护工照片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="hugonghugongzhaopianHandleAvatarSuccess">
              <img :style='{"border":"1px dashed #999","cursor":"pointer","color":"#999","borderRadius":"6px","textAlign":"center","background":"#f9f9f9","width":"200px","fontSize":"32px","lineHeight":"100px","height":"auto"}' v-if="sessionForm.hugongzhaopian" :src="baseUrl + sessionForm.hugongzhaopian" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="年龄" prop="nianling">
            <el-input v-model="sessionForm.nianling" placeholder="年龄" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="护工电话" prop="hugongdianhua">
            <el-input v-model="sessionForm.hugongdianhua" placeholder="护工电话" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="民族" prop="minzu">
            <el-input v-model="sessionForm.minzu" placeholder="民族" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="学历" prop="xueli">
            <el-input v-model="sessionForm.xueli" placeholder="学历" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="婚否">
            <el-select v-model="sessionForm.hunfou" placeholder="请选择婚否" >
              <el-option v-for="(item, index) in dynamicProp.hunfou" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='hugong'" label="护理级别">
            <el-select v-model="sessionForm.hulijibie" placeholder="请选择护理级别" >
              <el-option v-for="(item, index) in dynamicProp.hulijibie" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="家属账号" prop="jiashuzhanghao">
            <el-input v-model="sessionForm.jiashuzhanghao" placeholder="家属账号" readonly></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="密码" prop="mima">
            <el-input type="password" v-model="sessionForm.mima" placeholder="密码"></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="家属姓名" prop="jiashuxingming">
            <el-input v-model="sessionForm.jiashuxingming" placeholder="家属姓名" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="性别">
            <el-select v-model="sessionForm.xingbie" placeholder="请选择性别" >
              <el-option v-for="(item, index) in dynamicProp.xingbie" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="家属手机" prop="jiashushouji">
            <el-input v-model="sessionForm.jiashushouji" placeholder="家属手机" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="身份证" prop="shenfenzheng">
            <el-input v-model="sessionForm.shenfenzheng" placeholder="身份证" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="家庭住址" prop="jiatingzhuzhi">
            <el-input v-model="sessionForm.jiatingzhuzhi" placeholder="家庭住址" ></el-input>
          </el-form-item>
          <el-form-item :style='{"border":"0px solid #dfdfdf","padding":"10px","boxShadow":"0px 0px 0px #eee","margin":"0 0 10px","borderRadius":"0px","background":"none"}' v-if="userTableName=='jiashu'" label="头像">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :headers="headers"
              :show-file-list="false"
              :on-success="jiashutouxiangHandleAvatarSuccess">
              <img :style='{"border":"1px dashed #999","cursor":"pointer","color":"#999","borderRadius":"6px","textAlign":"center","background":"#f9f9f9","width":"200px","fontSize":"32px","lineHeight":"100px","height":"auto"}' v-if="sessionForm.touxiang" :src="baseUrl + sessionForm.touxiang" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item :style='{"padding":"0","margin":"20px 0 20px 0"}'>
            <el-button :style='{"border":"0","cursor":"pointer","padding":"0","boxShadow":"1px 2px 3px #aaa","margin":"0 20px 0 0","color":"#fff","outline":"none","borderRadius":"4px","background":"linear-gradient(180deg, rgba(17,112,210,1) 0%, rgba(8,179,68,1) 100%)","width":"128px","lineHeight":"40px","fontSize":"14px","height":"40px"}' type="primary" @click="onSubmit('sessionForm')">更新信息</el-button>
            <el-button :style='{"border":"1px solid #bbb","cursor":"pointer","padding":"0","boxShadow":"1px 2px 3px #ccc","margin":"0","color":"#999","outline":"none","borderRadius":"4px","background":"#fff","width":"128px","lineHeight":"40px","fontSize":"14px","height":"40px"}' type="danger" @click="logout">退出登录</el-button>
          </el-form-item>
        </el-form>
		
        <el-dialog title="用户充值" :visible.sync="dialogFormVisibleMoney" width="726px" center>
          <el-form :model="chongzhiForm">
            <el-form-item label="充值金额" label-width="120px">
              <el-input type="number" v-model="chongzhiForm.money" autocomplete="off" placeholder="充值金额"></el-input>
            </el-form-item>
            <el-form-item label-width="120px">
              <el-radio-group v-model="chongzhiForm.radio">
                <el-radio style="margin-bottom: 30px" label="微信支付">
                  <el-image
                    style="width: 60px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/weixin.png')"
                    fit="fill"></el-image>
                    <span style="display: inline-block;margin-left: 10px">微信支付</span>
                </el-radio>
                <el-radio label="支付宝支付">
                  <el-image
                    style="width: 60px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/zhifubao.png')"
                    fit="fill"></el-image>
                    <span style="display: inline-block;margin-left: 10px">支付宝支付</span>
                </el-radio>
                <el-radio label="中国建设银行支付">
                  <el-image
                    style="width: 120px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/jianshe.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="中国农业银行支付">
                  <el-image
                    style="width: 126px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/nongye.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="中国银行支付">
                  <el-image
                    style="width: 140px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/zhongguo.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="交通银行支付">
                  <el-image
                    style="width: 120px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/jiaotong.png')"
                    fit="fill"></el-image>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleMoney = false">取 消</el-button>
            <el-button type="primary" @click="chongzhi">确认充值</el-button>
          </div>
        </el-dialog>
        <el-dialog title="会员购买" :visible.sync="dialogFormVisibleVip" width="726px" center>
          <el-form :model="chongzhiForm">
            <el-form-item label="会员卡" label-width="120px">
              <el-input readonly autocomplete="off" value="￥199/年"></el-input>
            </el-form-item>
            <el-form-item label-width="120px">
              <el-radio-group v-model="chongzhiForm.radio">
                <el-radio style="margin-bottom: 30px" label="微信支付">
                  <el-image
                    style="width: 60px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/weixin.png')"
                    fit="fill"></el-image>
                    <span style="display: inline-block;margin-left: 10px">微信支付</span>
                </el-radio>
                <el-radio label="支付宝支付">
                  <el-image
                    style="width: 60px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/zhifubao.png')"
                    fit="fill"></el-image>
                    <span style="display: inline-block;margin-left: 10px">支付宝支付</span>
                </el-radio>
                <el-radio label="中国建设银行支付">
                  <el-image
                    style="width: 120px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/jianshe.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="中国农业银行支付">
                  <el-image
                    style="width: 126px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/nongye.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="中国银行支付">
                  <el-image
                    style="width: 140px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/zhongguo.png')"
                    fit="fill"></el-image>
                </el-radio>
                <el-radio label="交通银行支付">
                  <el-image
                    style="width: 120px; height: 60px;vertical-align: middle;"
                    :src="require('@/assets/jiaotong.png')"
                    fit="fill"></el-image>
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleVip = false">取 消</el-button>
            <el-button type="primary" @click="chongzhivip">确认支付</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
</div>
</template>

<script>
  import config from '@/config/config'
  import Vue from 'vue'
  export default {
    //数据集合
    data() {
      return {
        title: '个人中心',
        baseUrl: config.baseUrl,
        sessionForm: {},
        rules: {},
        chongzhiForm: {
          money: '',
          radio: ''
        },
        disabled: false,
        dialogFormVisibleMoney: false,
        dialogFormVisibleVip: false,
        uploadUrl: config.baseUrl + 'file/upload',
        imageUrl: '',
        headers: {Token: localStorage.getItem('Token')},
        userTableName: localStorage.getItem('UserTableName'),
        dynamicProp: {}
      }
    },
    created() {
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hugonggonghao', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'mima', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hugongxingming', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'xingbie', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hugongzhaopian', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'nianling', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hugongdianhua', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'minzu', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'xueli', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hunfou', null);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.sessionForm, 'hulijibie', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'jiashuzhanghao', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'mima', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'jiashuxingming', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'xingbie', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'jiashushouji', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'shenfenzheng', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'jiatingzhuzhi', null);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.sessionForm, 'touxiang', null);
      }

      if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'hugonggonghao', [{ required: true, message: '请输入护工工号', trigger: 'blur' }]);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'mima', [{ required: true, message: '请输入密码', trigger: 'blur' }]);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'hugongxingming', [{ required: true, message: '请输入护工姓名', trigger: 'blur' }]);
      }
			if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'nianling', [{ required: false, validator: this.$validate.isIntNumer, trigger: 'blur' }]);
      }
			if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'hugongdianhua', [{ required: false, validator: this.$validate.isMobile, trigger: 'blur' }]);
      }
      if ('hugong' == this.userTableName) {
        this.$set(this.rules, 'hulijibie', [{ required: true, message: '请输入护理级别', trigger: 'blur' }]);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.rules, 'jiashuzhanghao', [{ required: true, message: '请输入家属账号', trigger: 'blur' }]);
      }
      if ('jiashu' == this.userTableName) {
        this.$set(this.rules, 'mima', [{ required: true, message: '请输入密码', trigger: 'blur' }]);
      }
			if ('jiashu' == this.userTableName) {
        this.$set(this.rules, 'jiashushouji', [{ required: false, validator: this.$validate.isMobile, trigger: 'blur' }]);
      }
			if ('jiashu' == this.userTableName) {
        this.$set(this.rules, 'shenfenzheng', [{ required: false, validator: this.$validate.isIdCard, trigger: 'blur' }]);
      }

      this.init();
      this.getSession();
    },
    //方法集合
    methods: {
      init() {
        if ('hugong' == this.userTableName) {
          this.dynamicProp.xingbie = '男,女'.split(',');
        }
        if ('hugong' == this.userTableName) {
          this.dynamicProp.hunfou = '未婚,已婚'.split(',');
        }
        if ('hugong' == this.userTableName) {
          this.dynamicProp.hulijibie = '特级护理,一级护理,二级护理,三级护理'.split(',');
        }
        if ('jiashu' == this.userTableName) {
          this.dynamicProp.xingbie = '男,女'.split(',');
        }
      },
      getSession() {
        this.$http.get(this.userTableName + '/session', {emulateJSON: true}).then(res => {
          if (res.data.code == 0) {
            this.sessionForm = res.data.data;
            localStorage.setItem('userid', res.data.data.id);
            if(res.data.data.vip) {
                localStorage.setItem('vip', res.data.data.vip);
            }
            if(res.data.data.touxiang) {
                localStorage.setItem('headportrait', res.data.data.touxiang);
            } else if(res.data.data.headportrait) {
                localStorage.setItem('headportrait', res.data.data.headportrait);
            }
          }
        });
      },
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post(this.userTableName + '/update', this.sessionForm).then(res => {
              if (res.data.code == 0) {
                this.$message({
                  message: '更新成功',
                  type: 'success',
                  duration: 1500
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      hugonghugongzhaopianHandleAvatarSuccess(res, file) {
        if (res.code == 0) {
          if ('hugong' == this.userTableName) {
            this.sessionForm.hugongzhaopian = 'file/download/' + res.file;
          }
        }
      },
      jiashutouxiangHandleAvatarSuccess(res, file) {
        if (res.code == 0) {
          if ('jiashu' == this.userTableName) {
            this.sessionForm.touxiang = 'file/download/' + res.file;
          }
        }
      },
      chongzhi() {
        if (this.chongzhiForm.money == '') {
          this.$message({
            message: '请输入充值金额',
            type: 'error',
            duration: 1500
          });
          return;
        }
        if (this.chongzhiForm.money <= 0) {
          this.$message({
            message: '请输入正确的充值金额',
            type: 'error',
            duration: 1500
          });
          return;
        }
        if (this.chongzhiForm.radio == '') {
          this.$message({
            message: '请选择充值方式',
            type: 'error',
            duration: 1500
          });
          return;
        }
        this.sessionForm.money = parseInt(this.sessionForm.money) + parseInt(this.chongzhiForm.money);
        this.$http.post(this.userTableName + '/update', this.sessionForm).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: '充值成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.dialogFormVisibleMoney = false;
              }
            });
          }
        });
      },
      chongzhivip() {
        this.chongzhiForm.money == 199;
        if (this.chongzhiForm.radio == '') {
          this.$message({
            message: '请选择支付方式',
            type: 'error',
            duration: 1500
          });
          return;
        }
        if(this.sessionForm.vip == '是') {
          this.$message({
            message: '您已是我们的尊贵会员。',
            type: 'success',
            duration: 1500
          });
          return;
        }
        
        this.sessionForm.vip = "是"
        this.$http.post(this.userTableName + '/update', this.sessionForm).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: '会员购买成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                localStorage.setItem('vip', this.sessionForm.vip);
                this.dialogFormVisibleVip = false;
              }
            });
          }
        });
      },
      handleClick(tab, event) {
        switch(event.target.outerText) {
          case '个人中心':
            tab.$router.push('/index/center');
            break;
        }

        this.title = event.target.outerText;
      },
      logout() {
        localStorage.clear();
        Vue.http.headers.common['Token'] = "";
        this.$router.push('/index/home');
        this.activeIndex = '0'
        localStorage.setItem('keyPath', this.activeIndex)
        this.$forceUpdate()
        this.$message({
            message: '登出成功',
            type: 'success',
            duration: 1500,
        });
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .center-preview {
  
    .el-tabs {
      & /deep/ .el-tabs__header {
        .el-tabs__nav-wrap {
          margin: 0;
  
          &::after {
            content: none;
          }
        }
  
        .el-tabs__active-bar {
          display: none !important;
        }
      }
  
      .center-preview-pv {
        .el-date-editor.el-input {
          width: auto;
        }
  
        .balance {
          .el-input {
            width: auto;
          }
        }
      }
    }
  }
  
  .center-preview .el-tabs /deep/ .el-tabs__header {
	border-radius: 4px;
	padding: 16px 20px 16px 20px;
	box-shadow: 8px 8px 4px #eee;
	margin: 0 20px 0 0;
	background: #fff;
	width: 226px;
	border-color: #1170d2 #e6e6e6 #08b344 #eee;
	border-width: 4px 1px 4px 2px;
	position: relative;
	float: right;
	border-style: solid;
  }
  
  .center-preview .el-tabs /deep/ .el-tabs__header .el-tabs__item {
  	padding: 0 10px;
  	margin: 0 12px 8px 12px;
  	color: #333;
  	white-space: nowrap;
  	font-weight: 500;
  	font-size: 14px;
  	line-height: 40px;
  	text-overflow: ellipsis;
  	border-radius: 0;
  	box-shadow: 0px 2px 0px #eee;
  	overflow: hidden;
  	background: none;
  	position: relative;
  	text-align: center;
  	height: 40px;
  }
  
  .center-preview .el-tabs /deep/ .el-tabs__header .el-tabs__item:hover {
  	border-radius: 0;
  	padding: 0 10px;
  	box-shadow: 0px 2px 0px #eee;
  	margin: 0 12px 8px 12px;
  	color: #1170d2;
  	background: none;
  	font-weight: 500;
  	font-size: 14px;
  	line-height: 40px;
  	position: relative;
  	text-align: center;
  	height: 40px;
  }
  
  .center-preview .el-tabs /deep/ .el-tabs__header .el-tabs__item.is-active {
  	padding: 0 10px;
  	margin: 0 8px 8px 8px;
  	color: #1170d2;
  	font-weight: 600;
  	font-size: 14px;
  	border-color: #1170d2;
  	line-height: 40px;
  	border-radius: 0px;
  	box-shadow: -6px 6px 0px #eee;
  	background: none;
  	border-width: 1px 2px 4px 2px;
  	position: relative;
  	border-style: solid;
  	text-align: center;
  	height: 40px;
  }
  
  .center-preview .el-tabs /deep/ .el-tabs__content .el-tab-pane {
  	padding: 0 0 0 40px;
  	background: none;
  	width: 800px;
  	float: left;
  }
  
  .center-preview-pv .el-form-item /deep/ .el-form-item__label {
  	padding: 0 10px 0 0;
  	color: #666;
  	font-weight: 500;
  	width: 80px;
  	font-size: 14px;
  	line-height: 40px;
  	text-align: right;
  }
  
  .center-preview-pv .el-form-item .el-form-item__content {
    margin-left: 80px;
  }
  
  .center-preview-pv .el-input /deep/ .el-input__inner {
  	border: 1px solid #ccc;
  	border-radius: 4px;
  	padding: 0 12px;
  	outline: none;
  	color: #999;
  	background: #f9f9f9;
  	width: 400px;
  	font-size: 14px;
  	height: 40px;
  }
  
  .center-preview-pv .el-select /deep/ .el-input__inner {
  	border: 1px solid #ccc;
  	border-radius: 4px;
  	padding: 0 12px;
  	outline: none;
  	color: #999;
  	background: #f9f9f9;
  	width: 400px;
  	font-size: 14px;
  	height: 40px;
  }
  
  .center-preview-pv .el-date-editor /deep/ .el-input__inner {
  	border: 1px solid #ccc;
  	border-radius: 4px;
  	padding: 0 10px 0 30px;
  	outline: none;
  	color: #999;
  	background: #f9f9f9;
  	width: 200px;
  	font-size: 14px;
  	height: 40px;
  }
  
  .center-preview-pv /deep/ .avatar-uploader-icon {
  	border: 1px dashed #999;
  	cursor: pointer;
  	border-radius: 6px;
  	color: #999;
  	background: #f9f9f9;
  	width: 200px;
  	font-size: 32px;
  	line-height: 100px;
  	text-align: center;
  	height: auto;
  }
  
  .center-preview-pv .el-form-item.balance /deep/ .el-input__inner {
  	border: 1px solid #ccc;
  	border-radius: 4px;
  	padding: 0 12px;
  	outline: none;
  	margin: 0 8px 0 0;
  	color: #999;
  	background: #f9f9f9;
  	display: inline-block;
  	width: 200px;
  	font-size: 14px;
  	height: 40px;
  }
</style>
