import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=fdec3ac0&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"
import style0 from "./detail.vue?vue&type=style&index=0&id=fdec3ac0&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdec3ac0",
  null
  
)

export default component.exports