import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=361f2f84&scoped=true"
import script from "./detail.vue?vue&type=script&lang=js"
export * from "./detail.vue?vue&type=script&lang=js"
import style0 from "./detail.vue?vue&type=style&index=0&id=361f2f84&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361f2f84",
  null
  
)

export default component.exports